<template>
  <div id="app" style="padding:15px; overflow:auto; width:calc(100% - 30px)">
    <loading :loading="loading" text=""></loading>
      <el-form ref="form" :model="video"  label-width="120px">
        <el-form-item label="选择科目" :required="true">
          <subject-select ref="subjectSelect"  @parentEvent="getSubjectId"></subject-select>
        </el-form-item>
        <el-form-item label="百家云视频ID" :required="true">
          <el-input type="number" v-model="video.videoId" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="排序" :required="true">
          <el-input type="number" v-model="video.sort"    placeholder="请输入" ></el-input>
        </el-form-item>
        <el-form-item style="display: none">
          <el-button @click="submit()" id="layuiadmin-app-form-submit">提交</el-button>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  TipEnum,
  UrlEnum,
  request
} from "../../../../../public/js/common-vue";
import SubjectSelect from "@/components/SubjectSelect";
import Loading from "@/components/Loading";

export default {
  name: "VideoSave",
  data() {
    return {
      loading: false,
      id:0,
      sort:'',
      subjects:[],
      video:{
        videoId:'',
        subjectId:'',
        sort:''
      }
    }
  },
  components: {Loading, SubjectSelect},
  mounted() {
    if(request('id') != null && request('id') != ''){
      this.id=request('id');
      this.initData(null);
    }
    else{
      this.$refs.subjectSelect.initData(null);
    }
  },
  methods:{
    getSubjectId(subjectId){
      this.video.subjectId = subjectId;
    },
    submit:function (){
      if(this.video.subjectId == ""){
        this.showMsgError("请选择科目");
        return;
      }
      if(this.video.videoId == ""){
        this.showMsgError("清输入百家云视频ID");
        return;
      }
      if(this.video.sort == ''){
        this.showMsgError("请填写排序")
        return;
      }
      this.loading = true;
      let url = UrlEnum.SUBJECT_VIDEOS;
      let method = "post";
      if(this.id != 0){
        url += "/"+this.id;
        method = "put";
      }
      this.$http({
        method: method,
        url: url,
        data: this.video,
      })
      .then((res) => {
        this.loading=false;
        this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
        setTimeout(function () {
          window.parent.postMessage({
            msg: '',
            key: 'refresh'
          }, '*')
        }, 800);
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    initData(){
      this.loading = true;
      this.$http({
        method: 'GET',
        url: UrlEnum.SUBJECT_VIDEOS + "/" + request('id'),
        data: '',
      })
      .then((res) => {
        this.loading = false;
        this.video = res.data;
        this.$refs.subjectSelect.initData(this.video.subjectId);
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
  }
}
</script>

<style scoped>
</style>
