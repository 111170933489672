<template>
  <el-select v-model="subjectId" @change="handleChange" placeholder="请选择" style="width:100%">
    <el-option
        v-for="subject in subjects"
        :key="subject.id"
        :label="subject.name"
        :value="subject.id">
    </el-option>
  </el-select>
</template>

<script>
import {UrlEnum,getLocalProjectId} from "../../public/js/common-vue";

export default {
  name: "SubjectSelect",
  data(){
    return{
      subjectId:'',
      subjects:[]
    }
  },
  methods:{
    initData(checkedVal){
      this.$http({
        method: "get",
        url: UrlEnum.SUBJECT + '?page=1&size=100&projectId='+getLocalProjectId()+"&parentId=0",
      }).then((res) => {
        this.subjects = res.data.list;
        if(checkedVal != null){
          this.subjectId = checkedVal;
        }
        this.handleChange();
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    handleChange(){
      this.$emit('parentEvent',this.subjectId)
    },
    reset(){
      this.subjectId='';
      this.handleChange();
    }
  },
  mounted() {}
}
</script>

<style scoped>

</style>
